.input--checkbox {
  padding-left: calc(2 * var(--controlDiameter));

  @media print {
    display: none;
  }
}

input[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0, 0, 0, 0, 0, 0);
  -webkit-clip-path: polygon(0, 0, 0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  & + .input__label {
    display: inline-block;
    height: var(--controlDiameter);
    position: relative;

    &::after {
      background-color: var(--controlHighlight);
      box-sizing: border-box;
      content: '';
      display: inline-block;
      height: var(--controlInnerDiameter);
      left: calc(-2 * var(--controlDiameter) + 4px);
      position: absolute;
      top: calc(var(--controlInnerDiameter) / 2 - 1px);
      transform: scale(0);
      transition: var(--trans-all);
      width: var(--controlInnerDiameter);
    }

    &::before {
      border: 2px solid var(--controlBorderColor);
      border-radius: 2px;
      box-sizing: border-box;
      content: '';
      display: inline-block;
      position: absolute;
      height: var(--controlDiameter);
      left: calc(-2 * var(--controlDiameter));
      top: 0;
      transition: var(--trans-all);
      width: var(--controlDiameter);
    }
  }

  &:focus,
  &:active {
    & + .input__label::before {
      border-color: var(--controlHighlight);
    }
  }

  &:focus {
    & + .input__label {
      color: var(--color-green);
    }
  }

  &:checked {
    & + .input__label {
      &::after {
        background-color: var(--controlHighlight);
        transform: scale(1);
      }
    }
  }
}
