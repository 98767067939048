.ald-contact-form {
  background-color: white;
  padding: 4rem 0;

  @media print {
    display: none;
  }

  .contact {
    &__img {
      display: block;
      margin: 0 auto 2rem;
      max-width: 100px;
    }

    &__container {
      @media only screen and (min-width: 48rem) {
        align-content: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .primary,
        .secondary {
          width: calc(35% - 1.5rem);
        }

        .primary {
          margin-right: 1.5rem;
        }

        .secondary {
          margin-left: 1.5rem;
        }
      }

      .primary {
        margin-bottom: 2rem;
      }
    }

    &__heading {
      font-size: 2.25rem;
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 2rem;
    }

    &__form-item {
      & + * {
        margin-top: 1rem;
      }
    }

    &__label {
      color: var(--color-label-text);
      display: block;
      font-size: .75rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    &__input {
      background-color: var(--color-gray);
      border: 1px solid #d0d0d0;
      color: var(--color-dark);
      font-size: .875rem;
      padding: .5rem .75rem;
      width: 100%;

      &[aria-invalid="true"] {
        border-color: tomato;
      }

      &::placeholder {
        color: var(--color-label-text);
        opacity: .6;
        text-transform: none;
      }
    }

    &__response-msg {
      align-items: center;
      animation: fadeIn cubic-bezier(.25, 1, .30, 1) 1.25s;
      background-color: white;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      left: 0;
      padding: 2rem 1rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;

      &.is-loading {
        animation: fadeIn cubic-bezier(.25, 1, .30, 1) .5s;
        opacity: .9;
      }

      .response-msg__heading {
        font-size: 2.25rem;
        font-weight: 400;
        line-height: 1.3;
        margin: 1rem auto;
        max-width: 65%;

        & + * {
          margin: 1rem auto;
          max-width: 65%;
        }
      }

      .is-relative {
        height: 100%;
        width: 100%;
      }

      a {
        color: var(--color-blue);

        &:hover,
        &:active,
        &:focus {
          color: #222;
          text-decoration: underline;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
