.intro {
  @media only screen and (min-width: 48rem) {
    max-width: 42%;
  }

  & > * {
    color: white !important;
  }

  &__heading {
    font-weight: bold;
    margin-bottom: 2rem;
  }
}
