.molecule {
  order: 2;
  visibility: hidden;
  width: 0;

  &__inner {
    position: relative;
  }

  &__head {
    margin-bottom: .25rem;
  }

  &__sub-head {
    color: white;
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    text-transform: uppercase;
  }

  .is-active & {
    visibility: visible;
    width: 100%;

    @media only screen and (min-width: 48rem) {
      flex-grow: 2;
      order: 2;
      padding: 0 1.25rem;
      width: 45%;
    }

    @media print {
      display: none;
    }

    & > * {
      max-width: 100% !important;
      width: 100% !important;
    }

    & > canvas {
      pointer-events: none;

      @media only screen and (min-width: 48rem) {
        pointer-events: auto;
      }
    }
  }

  &__img {
    display: none;

    @media print {
      display: block;
      margin: 2rem 0 2rem auto;
      max-width: 50% !important;
      order: 2;
      width: 100%;
    }
  }
}
