.disclaimer {
  @media print {
    display: none;
  }

  .small {
    color: white;
    opacity: .8;
  }

  .input__label {
    cursor: pointer;
    font-size: .75rem;
    font-weight: bold;
  }
}
