.alloy-select {
  margin-top: 2rem;
  order: 3;

  @media print {
    float: left;
  }

  .is-active & {
    order: 1;
  }

  @media only screen and (min-width: 48rem) {
    margin-left: auto;
    margin-top: 0;
    max-width: 25%;
    order: 3;

    .is-active & {
      order: 3;
    }
  }

  & > * {
    color: white !important;
  }

  .btn--cta {
    @media print {
      display: none;
    }
  }

  .label-print-only {
    display: none;

    @media print {
      color: #000 !important;
      display: block;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
