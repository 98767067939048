.combo-box {
  margin-bottom: 1.5rem;
  position: relative;

  @media print {
    display: block;
    margin-bottom: 0;
    width: auto;
  }
}

.combobox__label {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: .5rem;
  text-transform: uppercase;

  @media print {
    display: none;
  }
}

.combobox__input,
.combobox__button {
  background: none;
  background-color: var(--color-dark);
  border: 1px solid var(--color-green);
  color: white;
  border-radius: 2px;
  padding: .5rem .75rem;
  position: relative;
  z-index: 100;
}

.combobox__input {
  border-right-color: transparent;
  font-weight: bold;
  height: 2.25rem;
  text-transform: capitalize;
  width: calc(100% - 14px - 1.5rem);

  &::placeholder {
    color: white;
    font-size: 12px;
    opacity: .9;
    text-transform: none;
  }

  @media print {
    border: 0;
    color: #000 !important;
    display: inline-block;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    padding-left: 0;
    width: auto;
  }
}

.combobox__button {
  border-left-color: transparent;
  height: 2.25rem;
  left: -4px;

  &:focus {
    outline-color: var(--color-blue);
  }

  @media print {
    display: none;
  }
}

.combobox__input:focus,
.combobox__input:active,
.combobox__button:active {
  outline: none;
}

.combobox_icon {
  height: auto;
  position: relative;
  top: -1px;
  width: 10px;

  @media print {
    display: none;
  }
}

.combobox__menu {
  color: white;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 100vh;
  max-width: calc(100% - 6px);
  opacity: .99;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: calc(100% - 1px);
  width: calc(100% - 4px);
  z-index: 200;

  @media print {
    display: none;
  }
}

.combobox__menu.is-open {
  border: 1px solid var(--color-green);
  border-top: 0;

  @media print {
    display: none;
  }
}

.combobox__menu-item {
  background-color: var(--color-dark);
  display: inline-block;
  font-size: .75rem;
  padding: .5rem .75rem;
  font-weight: bold;
  text-transform: capitalize;
  width: 100%;
}

.combobox__menu-item.highlight {
  background-color: var(--color-green);
}
