.hero {
  background-color: var(--color-dark);
  background-image: url('../../img/hero-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  margin: 0 auto;
  position: relative;
  padding-bottom: 8rem;
  padding-top: 10rem;
  width: 100%;

  @media only screen and (min-width: 48rem) {
    padding-bottom: 5rem;
  }

  &.is-active {
    background-image: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (min-width: 48rem) {
      flex-direction: row;
    }

    @media print {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .link--scroll {
    bottom: 2rem;
    left: calc(50% - (1.3125rem / 2));
    height: auto;
    position: absolute;
    width: 1.3125rem;
  }
}
