:root {
  --color-dark: #080e1c;
  --color-green: #29d9c2;
  --color-gray: #f4f4f4;
  --color-dark-gray: #252b35;
  --color-blue: #3499db;
  --color-label-text: #0b111d;
  --color-fine-print: #909298;
  --controlDiameter: 1.125rem;
  --controlDiameterVertOffset: -.3125rem;
  --controlInnerDiameter: calc(var(--controlDiameter) - 8px);
  --controlInnerDiameterVertOffset: -2px;
  --controlHighlight: var(--color-green);
  --controlBorderColor: var(--color-green);
  --trans-timing: .2s;
  --trans-function: cubic-bezier(.6, .04, .98, .335);
  --trans-delay: 0s;
  --trans-all: var(--trans-timing) var(--trans-function) var(--trans-delay);
}

* {
  box-sizing: border-box;
}

html,
body {
  box-sizing: inherit;
  color: #222;
  font-size: 16px;
  height: 100%;
  line-height: 1.2;
  min-height: 100%;
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}
