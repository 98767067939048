.small {
  font-size: .6875rem;
  line-height: 1.2;

  &--dark {
    color: var(--color-fine-print);
  }

  & a {
    color: var(--color-green);

    &:hover,
    &:active,
    &:focus {
      color: white;
    }
  }

  .fineprint__link {
    font-weight: bold;
    text-decoration: underline;
  }
}

.error {
  color: tomato;
  display: inline-block;
  margin-top: .25rem;
}
