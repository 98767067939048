.alloy-graph {
  color: white;
  order: 3;

  @media only screen and (min-width: 48rem) {
    max-width: 27.5%;
    order: 1;
  }

  @media print {
    flex-grow: 3;
    margin-top: -5rem;
    order: 3;
    width: 100%;
  }

  &__formula {
    color: white;
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: .25rem;
    text-align: center;
  }

  &__graph-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  &__graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 1rem);

    &-one {
      .alloy-graph__bar {
        background-color: var(--color-green);
        color: var(--color-green) !important;

        @media print {
          color: var(--color-green) !important;
        }

        &.is-pos ~ .quad-pos,
        &.is-neg ~ .quad-neg {
          background-color: rgba(41, 217, 194, .07);
        }
      }
    }

    &-two {
      .alloy-graph__bar {
        background-color: var(--color-blue);
        color: var(--color-blue);

        @media print {
          color: var(--color-blue) !important;
        }

        &.is-pos ~ .quad-pos,
        &.is-neg ~ .quad-neg {
          background-color: rgba(52, 153, 219, .07);

          @media print {
            background-color: rgba(52, 153, 219, .07);
            -webkit-print-color-adjust: exact !important;
          }
        }
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
  }

  &__num,
  &__bar {
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &__num {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    top: calc(25% - 0.125rem);
    z-index: 2;

    &.is-neg {
      top: calc(75% - 1rem);
    }
  }

  &__bar {
    top: calc(50% + 2px);
    z-index: 0;

    rect {
      fill: currentColor;
      height: 100%;
      width: 100%;

      @media print {
        width: 100% !important;
      }
    }

    &.is-pos {
      top: calc(50% - 2px);
      transform: rotate(180deg);
      transform-origin: top center;

      & ~ .quad-neg {
        background-color: rgba(255, 255, 255, .03);

        @media print {
          background-color: rgba(255, 255, 255, .03);
          -webkit-print-color-adjust: exact !important;
        }
      }
    }

    &.is-neg {
      & ~ .quad-pos {
        background-color: rgba(255, 255, 255, .03);

        @media print {
          background-color: rgba(255, 255, 255, .03);
          -webkit-print-color-adjust: exact !important;
        }
      }
    }
  }

  &__label {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: .5rem 0;
    text-align: center;
    width: 100%;
  }

  &__actions {
    margin: 2rem 0;

    @media print {
      display: none;
    }
  }

  .quad {
    background-color: transparent;
    background-image: url('../../img/graph-background.png');
    background-position: center;
    background-repeat: repeat-y;
    background-size: 100%;
    height: 59px;
    margin-bottom: 4px;
    position: relative;
    z-index: 1;

    @media print {
      background-image: url('../../img/graph-background.png');
      -webkit-print-color-adjust: exact !important;
    }

    & + .quad {
      margin-top: 4px;

      &::before {
        content: '';
        display: block;
        background-color: var(--color-dark-gray);
        height: 1px;
        left: 0;
        position: absolute;
        top: -4px;
        width: 100%;
        z-index: 500;
      }
    }
  }
}
