* {
  @media print {
    /* overriding inherited Bootstrap rules */
    background: inherit !important;
    color: inherit !important;
  }
}

.btn--cta {
  background-color: var(--color-green) !important;
  border-color: var(--color-green) !important;
  margin-top: 1rem;
  transition: var(--trans-all);

  &[disabled] {
    cursor: not-allowed !important;
    pointer-events: auto;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--color-green) !important;
      border-color: var(--color-green) !important;
      color: white !important;
      opacity: .65;
      outline: none;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-dark) !important;
    color: var(--color-green) !important;
    outline: none;
  }
}

button.button--icon,
a.button--icon {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: var(--color-green);
  display: block;
  margin: 0 0 1.125rem;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    color: white !important;
  }

  .icon,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    color: currentColor !important;
    height: auto;
    margin-right: 2ch;
    width: 20px;

    g, path {
      fill: currentColor !important;
    }
  }

  span {
    color: currentColor !important;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.menu ,
.social-icons,
a[href]:after,
.footer-container .col-sm-4:last-of-type,
.footer-container .sub a {
  @media print {
    display: none !important;
  }
}

.subnav {
  z-index: 100;
}

.overlay-bar {
  background-color: transparent !important;
}
